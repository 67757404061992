/**
 * Type: ページ
 * What: 絶景3兄弟 SMBC日興証券-のぼZOページ
 */
import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { scroller } from 'react-scroll';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import { Btn, Button, Text, TitleZekkei } from '../../components/Btn';
import Image from '../../util/Image';
import BreadcrumbComponent from '../../components/Breadcrumb';
import SimpleSwiper from '../../components/Slider.fade';
import RfStatusComponent from '../../components/PostList/RfStatusComponent';
import { Accordion } from '../../components/Accordion';
import useMediaQuery from '../../util/useMediaQuery';
import ModalContent from '../../components/ZekkeiModal';

const pageTitle = 'のぼZO SMBC日興証券';
const pageDescription =
  'E・ZO FUKUOKAの屋上アトラクション。気分は絶壁を登るクライマー！？屋上設置のクライミング＆ボルダリング！クライミングの最高到達点は地上約50m！';
const pageSlug = 'zekkei-brothers';
// const pageLogo = `logo-${pageSlug}.png`;
// const pagebtnLogo = `btn-${pageSlug}.png`;

const sliderDataobj = [
  {
    filename: 'kv-zekkei-nobozo.jpg',
  },
];
export const query = graphql`
  {
    site {
      siteMetadata {
        ticket
        ticketReady
        uuidNoboZo
        uuidNoboZoBold
        zekkeiReady
      }
    }
  }
`;

const mq = useMediaQuery;

// Data Props Template
const Template = ({ data }) => {
  const { ticket } = data.site.siteMetadata;
  // const uuid = data.site.siteMetadata.uuidNoboZo;
  // const uuidBold = data.site.siteMetadata.uuidNoboZoBold;
  const uuid = data.site.siteMetadata;
  const isTicket = data.site.siteMetadata.ticketReady;
  const isZekkei = data.site.siteMetadata.zekkeiReady;

  const scrollToTarget = (elem, offset) => {
    const $offset = offset || -110;
    scroller.scrollTo(elem, {
      duration: 1200,
      delay: 0,
      offset: $offset,
      smooth: 'easeOutQuint',
    });
  };

  const openPdf = () => {
    /* eslint no-undef: 0 */
    const lang = WOVN.io.getCurrentLang().code;
    const href = `https://e-zofukuoka.com/files/zekkei-brothers/rule-nobozo-${lang}.pdf`;
    window.open(href);
  };

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bg-8 arrow">
        <div className="kv-wrap">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
          </div>
          <div className="container">
            <div className="inner">
              <div className="pageMain-header">
                <div className="floorNo c-8">
                  <span>R</span>
                  <span>F</span>
                </div>
                <h2 className="ttl">
                  <Image src="logo-zekkei-nobozo-h.png" alt={pageTitle} />
                </h2>
              </div>

              {isZekkei === '1' ? <RfStatusComponent col="3" /> : null}

              <div className="overview">
                <p className="text fwb">
                  気分は絶壁を登るクライマー！？
                  <br />
                  屋上設置のクライミング＆ボルダリング！クライミングの最高到達点は地上約50m！
                </p>
              </div>
              <p className="has-text-align-center attention has-text-weight-bold mb-4 text is-3">
                屋外アトラクションのため体験中の気温対策はお客様ご自身でお願いします。
              </p>
              <p className="has-text-align-center attention has-text-weight-bold mb-4 text is-5">
                チケットは当日券のみ、
                <br className="sp" />
                BOSS E・ZO FUKUOKA
                <br />
                8Fに設置の専用券売機にてお買い求めいただけます。
              </p>
              {/* {isTicket === '1' ? (
                <>
                  <ModalContent
                    ticket={ticket}
                    uuid={uuid.uuidNoboZo}
                    title="クライミングTICKET"
                    titleInner="クライミングチケット購入"
                    page="nobozo"
                    description="ロープ（オートビレイ）を付けて登る高さ9ｍのウォール"
                    caution="※利用時間は各枠約15分です。<br />※OPENから15時ころまでは、ホールド（持ち手）やマットが強い日差しにより高温となるため、夕方以降のご利用がお勧めです。"
                  />
                  <ModalContent
                    ticket={ticket}
                    uuid={uuid.uuidNoboZoBold}
                    title="ボルダリングTICKET"
                    titleInner="ボルダリングチケット購入"
                    page="nobozo"
                    description="初心者向けの横移動して利用する高さ3ｍのウォール"
                    caution="※利用時間は各枠約15分です。<br />※OPENから15時ころまでは、ホールド（持ち手）やマットが強い日差しにより高温となるため、夕方以降のご利用がお勧めです。"
                  />
                </>
              ) : null} */}
              <p className="desc-pre has-text-align-center">
                クライミング：ロープ（オートビレイ）を付けて登る高さ9ｍのウォール
              </p>
              <p className="desc-pre has-text-align-center">
                ボルダリング：初心者向けの横移動して利用する高さ3ｍのウォール
              </p>
              {/* <div className="btn-group mt-5">
                <Link
                  to="/news/info/20230311252/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>
                    新型コロナウイルス感染防止の対策について
                  </span>
                </Link>
              </div> */}
              <div className="youtube-wrap mt-6 mb-0 cn-hide">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/4RD5ASNr31w"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>

        {/*        <div className="container">
          <div className="inner">
            <div className="section-link">
              <ul>
                <li><a href="#attraction" onClick={() => scrollToTarget('attraction', -66)}><span className="arrow">劇場紹介</span></a></li>
                <li><a href="#information" onClick={() => scrollToTarget('information', -30)}><span className="arrow">料金</span></a></li>
                <li><a href="#"><span className="arrow">よくある質問</span></a></li>
              </ul>
            </div>
          </div>
        </div> */}
      </section>
      {/*      <section className="section sc-topics bs-topics" id="topics">
        <div className="container">
          <h2 className="headline"><span>Topics</span></h2>
          <PostListComponent />
          <div className="btn-wrap">
            <Btn href="/news/" className="btn-l btn-ticket bc-1">
              <Text>
                一覧をみる
              </Text>
            </Btn>
          </div>
        </div>
      </section> */}
      <section className="section sc-attraction bg-13 none" id="attraction">
        <div className="container">
          {mq('pc') ? (
            <h2 className="title is-2 jp shadow3">
              <TitleZekkei width={1076} shadow="50.5%">
                E・ZOの屋上壁面を利用したクライミング！
              </TitleZekkei>
              <TitleZekkei width={1076} shadow="50.5%">
                まさに絶壁を登るクライマーの気分を体感できる！
              </TitleZekkei>
            </h2>
          ) : (
            <h2 className="title is-2 jp shadow3 lh-0">
              <TitleZekkei width={760} shadow="50.5%">
                E・ZOの屋上壁面を利用した
              </TitleZekkei>
              <TitleZekkei width={760} shadow="50.5%">
                クライミング！
              </TitleZekkei>
              <TitleZekkei width={760} shadow="50.5%">
                まさに絶壁を登る
              </TitleZekkei>
              <TitleZekkei width={760} shadow="50.5%">
                クライマーの気分を体感
              </TitleZekkei>
            </h2>
          )}
          <div className="content mb60">
            <p className="text is-2 is-3-sp ls-5 lh-9">
              ビル最上階から更に高さ10Mのウォールがそびえたつ！最高到達点は地上から５０M・・・
              <br />
              オリンピック競技にも選ばれたホットなコンテンツをここだけの絶景を見ながら体験できる！
            </p>
          </div>

          {mq('pc') ? (
            <h2 className="title is-2 jp shadow3 mb0">
              <TitleZekkei width={1076} shadow="50.5%">
                ２パターンのウォールを用意！
              </TitleZekkei>
            </h2>
          ) : (
            <h2 className="title is-2 jp shadow3 lh-0 mb0">
              <TitleZekkei width={760} shadow="50.5%">
                ２パターンのウォールを用意！
              </TitleZekkei>
            </h2>
          )}

          {mq('pc') ? (
            <h2 className="title is-2 jp shadow3 mb25">
              <TitleZekkei width={1076} shadow="50.5%">
                のぼZO SMBC日興証券(クライミング)
              </TitleZekkei>
            </h2>
          ) : (
            <h2 className="title is-2 jp shadow3 lh-0">
              <TitleZekkei width={760} shadow="50.5%">
                のぼZO SMBC日興証券(クライミング)
              </TitleZekkei>
            </h2>
          )}
          <div className="wrap-sp mb45 sp-mb30">
            <div className="col6">
              <div className="col6-inner ">
                <div className="image">
                  <Image filename="img-nobozo1.jpg" />
                </div>
              </div>
            </div>
          </div>
          <div className="wrap-sp mb45 sp-mb30">
            <div className="col6">
              <div className="col6-inner ">
                <div className="image">
                  <Image filename="img-nobozo2.jpg" />
                </div>
              </div>
            </div>
          </div>
          <p className="text is-2 is-3-sp ls-5 lh-9 mb35">
            ビル屋上の壁面を利用した、高さ約9ｍのクライミングウォール。
            <br />
            壁の最上部から確保されたロープを装着し、頂上を目指します。最高到達地点はなんと地上約50ｍ！
          </p>
          {/* <h3 className="tac fwb text is-11 is-8-sp mb25">のぼZO SMBC日興証券(ボルダリング)</h3> */}
          {mq('pc') ? (
            <h2 className="title is-2 jp shadow3 mb-20">
              <TitleZekkei width={1076} shadow="50.5%">
                のぼZO SMBC日興証券(ボルダリング)
              </TitleZekkei>
            </h2>
          ) : (
            <h2 className="title is-2 jp shadow3 lh-0">
              <TitleZekkei width={760} shadow="50.5%">
                のぼZO SMBC日興証券(ボルダリング)
              </TitleZekkei>
            </h2>
          )}
          <div className="flex mb35">
            <div className="half-padding-right sp-mb30">
              <div className="image">
                <Image filename="img-nobozo5.jpg" />
              </div>
            </div>
            <div className="half-padding-left">
              <div className="image">
                <Image filename="img-nobozo3.jpg" />
              </div>
            </div>
          </div>
          <p className="text is-2 is-3-sp ls-5 lh-9 mb35">
            ビル屋上の壁面を利用した高さ3mのボルダリング。
            <br />
            壁が低く専用の装備は必要ないため、お子様でも気軽にご利用いただけます
          </p>
        </div>
        <div className="container">
          <div className="flex mb35">
            <div className="half-padding-right sp-mb30">
              <div className="image">
                <Image filename="img-nobozo6.jpg" />
              </div>
            </div>
            <div className="half-padding-left">
              <div className="image">
                <Image filename="img-nobozo7.jpg" />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          {mq('pc') ? (
            <h2 className="title is-2 jp shadow3">
              <TitleZekkei width={1132} shadow="50.5%">
                絶景を望みながらここだけの体験を！
              </TitleZekkei>
            </h2>
          ) : (
            <h2 className="title is-2 jp shadow3 lh-0">
              <TitleZekkei width={760} shadow="50.5%">
                絶景を望みながら
              </TitleZekkei>
              <TitleZekkei width={760} shadow="50.5%">
                ここだけの体験を！
              </TitleZekkei>
            </h2>
          )}

          <div className="content mb30">
            <p className="text is-1 ls-5 lh-9">
              ビル屋上というロケーションを最大限に活用した爽快感、達成感を気軽に楽しめます！
            </p>
          </div>
          <div className="flow-row mb60">
            <div className="col12">
              <div className="col6-inner ">
                <div className="image">
                  <Image filename="img-nobozo8.jpg" />
                </div>
              </div>
              {/* <p>リードクライミング</p> */}
            </div>
          </div>

          <h3 className="fwb text is-7 is-5-sp mb10">
            間近で応援できる休憩スペースも設置（見学は無料です）
          </h3>
          <p className="text is-2 is-3-sp ls-5 lh-9 mb35">
            友人やお子様が楽しんでいる様子を見てみたい！そんな方にゆっくりとご見学いただけるベンチスペースも用意！
          </p>

          <h3 className="fwb text is-7 is-5-sp mb10">
            持ち手によって難易度変化
          </h3>
          <p className="text is-2 is-3-sp ls-5 lh-9 mb35">
            ウォールには様々な色のホールドがあり、カラーによって難易度が分かれています。さまざまなホールドで何度もトライ！
          </p>

          <h3 className="fwb text is-7 is-5-sp mb10">手ぶらで体験が可能！</h3>
          <p className="text is-2 is-3-sp ls-5 lh-9 mb35">
            ウォールを登るための専用靴や道具は一切なし！気軽に体験が可能！
            <br />
            ※動きやすい恰好で来ていただく必要があります
          </p>

          <p>
            ※利用時間は各枠約10分です。
            <br />
            ※OPENから15時ころまでは、ホールド（持ち手）やマットが強い日差しにより高温となるため、夕方以降のご利用がお勧めです。
          </p>
        </div>
      </section>
      <section className="section bg-13 bs-8" id="information">
        <div className="container">
          <div className="inner-slim bg-14">
            <h3 className="headline">
              <span>Information</span>
            </h3>

            <h4 className="text fwb is-3 is-4-sp mb15">料金・対象</h4>
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>
                      料金
                      <br />
                      （利用時間約10分）
                    </th>
                    <td>
                      <p className="fwb">クライミング</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>1,000円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>900円</b>
                        </dd>
                      </dl>
                      <p className="mt30 fwb">ボルダリング</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>600円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>500円</b>
                        </dd>
                      </dl>
                      <p className="mt30">
                        各日程のレギュラー・ピークは空席情報カレンダーでご確認ください。
                      </p>
                      <p>
                        <a href="/ticket/" className="tx-link">
                          空席情報カレンダーはこちら
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>対象年齢</th>
                    <td>
                      <p>
                        クライミング：7歳～
                        <br />
                        ボルダリング：4歳～
                      </p>
                      <p className="list-mark">
                        ※4歳～6歳のご利用には保護者の同伴が必要（同伴者が体験されない場合、チケット購入は不要）
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>体重</th>
                    <td>
                      クライミング：20㎏～150㎏（着衣を含みます）
                      <br />
                      ボルダリング：150㎏未満（着衣を含みます）
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <article>
              <p className="">【以下に該当する方はご利用いただけません】</p>
              <p>
                ・当施設は安全面の理由から、身長・体重・年齢で下記利用制限を設けております。
              </p>
              <p className="ttl-strip">クライミング</p>
              <ol className="mb30">
                <li>
                  ①6歳以下、着衣を含み体重が20Kｇ未満、または150Kg以上の方
                </li>
                <li>
                  ②・酒気を帯びた方
                  <br />
                  ・妊娠中の方
                  <br />
                  ・心疾患をお持ちの方
                  <br />
                  ・ご自身での自立歩行が難しい方
                  <br />
                  ・その他健康上不安のある方
                  <br />
                  ・ハーネスが正しく（フィットした状態で）装着できない
                </li>
              </ol>
              <p className="ttl-strip">ボルダリング</p>
              <ol className="mb30">
                <li>①3歳以下、着衣を含み体重が体重が150Kg以上の方</li>
                <li>
                  ②・酒気を帯びた方
                  <br />
                  ・妊娠中の方
                  <br />
                  ・心疾患をお持ちの方
                  <br />
                  ・ご自身での自立歩行が難しい方
                  <br />
                  ・その他健康上不安のある方
                </li>
              </ol>
              {/* {isTicket === '1' ? (
                <ModalContent
                  ticket={ticket}
                  uuid={uuid.uuidNoboZo}
                  title="クライミングTICKET"
                  titleInner="クライミングチケット購入"
                  page="nobozo"
                  description="ロープ（オートビレイ）を付けて登る高さ9ｍのウォール"
                  caution="※利用時間は各枠約15分です。<br />※OPENから15時ころまでは、ホールド（持ち手）やマットが強い日差しにより高温となるため、夕方以降のご利用がお勧めです。"
                />
              ) : null} */}
              <p className="has-text-align-center has-text-weight-bold mb-4 is-size-6">
                ※ご利用当日に利用同意書が必要です。
                <br />
                また、12歳以下の場合、保護者の方（18歳以上）のご署名が必要です。
                <br />
                ご利用する当日は、保護者のサインが入った同意書を必ずご持参ください。
              </p>
              <div
                className="btn-wrap mb-5 flx-col"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className="btn-regular has-text-align-center"
                  onClick={openPdf}
                >
                  <span>のぼZO同意書・利用規約（PDF）</span>
                  <svg
                    viewBox="0 0 490.8 490.8"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                    <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                  </svg>
                </div>
              </div>
              {/* <div className="btn-group mt-5">
                <Link
                  to="/news/info/20230311252/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>
                    新型コロナウイルス感染防止の対策について
                  </span>
                </Link>
              </div> */}
              <p className="has-text-align-center attention has-text-weight-bold mb-4 is-size-6">
                チケットは当日券のみ、BOSS E・ZO FUKUOKA
                <br />
                8Fに設置の専用券売機にてお買い求めいただけます。
              </p>
            </article>
            <div className="inner-slim p00 bg-14">
              <Accordion
                Name="利用規約はこちら"
                Title="利用規約"
                Close="利用規約を閉じる"
                bg="bg-14"
                open
              >
                {/* <h3>段落タイトル段落タイトル段落タイトル</h3> */}
                <div className="content">
                  <p className="list-mark3">
                    ・当施設内での怪我、損失、損害、トラブルなどにつきまして、弊社の責めに帰すべき事由がある場合を除き、弊社は一切の責任を負いません。
                  </p>
                  <p className="list-mark3">
                    ・スタッフにより安全にご参加いただけないと判断した場合、参加をお断りする場合がございます。
                  </p>
                  <p className="list-mark3">
                    ・スタッフの指示には必ず従って下さい。指示及び注意事項に従わなかった場合の怪我などの障害及び損害については責任を負いかねます。
                  </p>
                  <p className="list-mark3">
                    ・安全確認のため、身長・体重の測定をお願いする場合がございます。測定後に利用制限に該当する場合はご参加をお断りさせていただきます。
                  </p>
                </div>
              </Accordion>
            </div>
            <article>
              <h4 className="text fwb is-3 is-4-sp mb15">注意事項</h4>
              {/* <p className="mb15">【以下に該当する方はご利用いただけません】</p> */}
              <ol className="mb30">
                <li>
                  ・事前準備がある為、チケット記載の指定時間10分前までに8階受付までお越しください。指定時間を過ぎた場合はご参加いただけません。
                </li>
                <li>
                  ・スカートやワンピース、コートなどの衣類やサンダル、ハイヒールなど参加中に落下の恐れのある履物を着用してのご参加はできません。動きやすい服装かつスニーカーでご参加ください。
                </li>
                <li>・状況により、ご利用をお待ちいただく場合がございます。</li>
                <li>
                  ・天候不良等によりご参加いただけない場合は払い戻しをさせていただきます。
                </li>
                <li>
                  ・屋外アトラクションのため体験中の気温対策はお客様自身でお願いします。
                </li>
                <li>
                  ・手荷物（携帯電話、カメラなど身の回りの所持品含む）は全てロッカーに預けていただきます。
                </li>
                <li>
                  ・携帯電話やスマートフォン、財布や時計などをポケットの中に入れたままにしておくと、落下・破損・紛失の恐れがあります。ポケットの中は必ず空にしてください。
                </li>
              </ol>
            </article>
          </div>
        </div>
      </section>
      <section className="section sc-flow bg-14" id="flow">
        <div className="container">
          <h2 className="headline">
            <span>Flow</span>
          </h2>
          <div className="border-box bs-8 stack">
            <Image filename="img-flow-nobozo.png" />
          </div>

          <div className="content border-box bg-12 nobozo">
            <h3>8F 受付へお越しください</h3>
            <p>
              <span>1</span>
              受付で手続きをする
              <br />
              ・チケットをご提示ください。チケット情報の確認を行います。
              <br />
              ・ロッカー専用コインをお渡しします。
              <br />
            </p>
            <p>
              <span>2</span>
              同意書への記載。内容をよく確認のうえ記載をお願いいたします
            </p>
            <p>
              <span>3</span>
              専用ロッカーへの手荷物収納 ※手荷物は全てお預けください
            </p>
            <p>
              <span>4</span>
              スタートまで待機列にてお待ちください
            </p>
          </div>

          <div className="inner p00 bg-14">
            <div className="introduction">
              <p className="tac fwb">▼　その他の屋上アトラクションを見る　▼</p>
              <div className="row j-center colum-sp">
                <div className="col">
                  <Link to="/zekkei-brothers/">
                    <Image
                      filename="logo-zekkei-brothers-l.png"
                      alt="絶景3兄弟 SMBC日興証券"
                    />
                    <span className="text fwb">
                      絶景3兄弟 SMBC日興証券TOPページへ戻る
                    </span>
                  </Link>
                </div>
                <div className="col">
                  <Link to="/zekkei-brothers/subezo/">
                    <Image
                      filename="logo-zekkei-subezo.png"
                      alt="すべZO | 絶景3兄弟 SMBC日興証券"
                    />
                  </Link>
                </div>
                <div className="col">
                  <Link to="/zekkei-brothers/tsurizo/">
                    <Image
                      filename="logo-zekkei-tsurizo.png"
                      alt="つりZO | 絶景3兄弟 SMBC日興証券"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Template;
